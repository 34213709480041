<template>
  <nuxt-link class="related-collection-alternative aspect-1" :to="`/${slug}/`">
    <Image loading="lazy" :title="name" :alt="name"
           :src="image"/>
    <div class="name"> {{ name }}</div>
    <div class="previews">
      <template v-for="preview in previews.slice(0,4)">
        <div class="preview no-flex" v-if="preview">
          <div class="img full-width aspect-1">
            <Image :alt="name"
                   :src="preview.path"/>
          </div>
        </div>
      </template>
    </div>
  </nuxt-link>
</template>

<script>
import Image from "~/components/atoms/Image.vue";

export default {
  components: {Image},
  props: {
    slug: String,
    name: String,
    image: String,
    previews: {
      type: Array,
      default: () => [],
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/mixins.scss";

.related-collection-alternative {
  border-radius: 5px;
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 0.87;

  @include mobile {
    width: 95%;
  }

  .name {
    line-height: 21px;
    padding: 7px 16px 6px;
    background: #000;
    letter-spacing: 0.44px;
    color: #fff;
    font-size: 16px;
    margin-top: -5px;
    min-width: 160px;
    text-align: center;
    text-transform: uppercase;
    bottom: 12%;
    position: absolute;

    @include mobile {
      font-size: 11px;
      min-width: 76px;
      padding: 5px 14px 4px;
    }
  }

  & > img {
    border-radius: 5px;
    width: 100%;
    height: 100%;

    @include smd {
      height: unset;
    }
  }

  .previews {
    position: absolute;
    right: 24px;
    top: 23px;

    .preview {
      position: relative;
      width: 48px;
      height: 48px;
      margin-bottom: 10px;
      border: 1px solid #fff;
      border-radius: 100%;
      overflow: hidden;

      @include smd {
        width: 33px;
        height: 33px;
      }

      .img {
        scale: 1.1;
      }

      &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
        display: block;
      }
    }
  }
}

</style>
